import { HashRouter, Route, Routes } from 'react-router-dom';
import './css/App.css';
import './css/Common.css';
import './css/Reset.css';
import SignIn from './template/SignIn';
import AuthContextProvider from './context/AuthContext';
import Home from './template/Home';
import PrivateRoute from './PrivateRoute';
import Contents from './template/Contents';
import MyPage from './template/MyPage';

function App() {
  return (
    <AuthContextProvider>
      <HashRouter>
        <Routes>
          <Route path={'/'} element={<SignIn />} />
          <Route 
            path={'/home'} 
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            } 
          />
          <Route 
            path={'/contents'} 
            element={
              <PrivateRoute>
                <Contents />
              </PrivateRoute>
            } 
          />
          <Route 
            path={'/mypage'} 
            element={
              <PrivateRoute>
                <MyPage />
              </PrivateRoute>
            } 
          />
        </Routes>
      </HashRouter>
    </AuthContextProvider>
  );
}

export default App;
