import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";

const Contents = () => {
    const [user, setUser] = useState(null);
    useEffect(() => {
        const uid = auth.currentUser.uid;
        const userRef = doc(db, 'company', uid);
        getDoc(userRef).then(snapshot => {
            const data = snapshot.data();
            setUser(data)
        })
    }, [])
    return(
        <div className="vr_view">
            {user && 
                <iframe width="100%" height="100%" src={user.url} />
            }
        </div>
    )
}

export default Contents