import React, { useEffect, useState } from "react";
import Logo from "../img/linx_logo.png";
import { useAuth } from "../context/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";

const MyPage = () => {
    const { logout } = useAuth();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const uid = auth.currentUser.uid;
        const userRef = doc(db, 'company', uid);
        getDoc(userRef).then(snapshot => {
            const data = snapshot.data();
            setUser(data)
        }) 
    }, [])
    return(
        <div className="root background">
            <div className="info_container position-center">
                <div className="mypage_info">
                    <label>Company Name</label>
                    <p>{user&& user.company}</p>
                </div>
                <div className="space_module_small" />
                <div className="mypage_info">
                    <label>Email</label>
                    <p>{user&& user.email}</p>
                </div>
                <div className="space_module_midium" />
                <div onClick={() => logout()} className="logout_button">
                    <button>Log out</button>
                </div>
            </div>
            <div className="bottom_logo">
                <img src={Logo} />
                <p>PROMOTION EDITION</p>
            </div>
        </div>
    )
}

export default MyPage;