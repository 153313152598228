import React, { useCallback, useState } from "react";
import Logo from "../img/linx_logo.png";
import { useAuth } from "../context/AuthContext";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const inputEmail = useCallback((e) => {
        setEmail(e.target.value)
    }, [setEmail]);
    const inputPassword = useCallback((e) => {
        setPassword(e.target.value)
    }, [setPassword]);

    const { signIn, user } = useAuth();

    const handleSubmit = async(e) => {
        setIsLoading(true);
        try {
            await signIn(email, password);
            setIsLoading(false);
            navigate('/home')
        } catch(e) {
            window.alert(e);
            setIsLoading(false);
        }
    }
    return(
        <div className="root background">
            {isLoading ? <Loading label={"ログイン中..."} /> : <div className="display_none" />}
            <div className="logo">
                <img src={Logo} alt="LiNX Promotion" />
                <p>PROMOTION EDITION</p>
            </div>
            <div className="container form_scale position-center p-all40">
                <h2>Sign In</h2>
                <div className="space_module_midium" />
                <div className="form_item">
                    <label>Email</label>
                    <input type="email" value={email} onChange={inputEmail} />
                </div>
                <div className="form_item">
                    <label>Password</label>
                    <input type="password" value={password} onChange={inputPassword} />
                </div>
                <p>Do you forget password?</p>
                <div className="space_module_midium" />
                <div className="form_button">
                    <button onClick={() => handleSubmit()}>Sign In</button>
                </div>
            </div>
        </div>
    )
}

export default SignIn;