import { CircularProgress } from "@mui/material";
import React from "react";

const Loading = ({label}) => {
    return(
        <div className="loging">
            <CircularProgress />
            <p>{label}</p>
        </div>
    )
}

export default Loading;