import { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { CircularProgress } from "@mui/material";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({children}) {
    const [user, setUser] = useState("");
    const [loading, setLoading] = useState(true);

    function signIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password).then(result => {
            const user = result.user;
            if(user) {
                const uid = user.uid;
                const userRef = doc(db, 'company', uid);
                return getDoc(userRef).then(snapshot => {
                    const data = snapshot.data();
                    if(!data) {
                        alert('ユーザーデータが存在しません。');
                    }
                })
            }
        })
    }

    function logout() {
        return signOut(auth);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });
        return () => {
            unsubscribe();
        }
    }, [])

    if(loading) {
        return<div className="loging">
            <CircularProgress />
            <p>Loading...</p>
        </div>
    }
    return <AuthContext.Provider value={{user, signIn, logout}}>
        {children}
    </AuthContext.Provider>
}