import React from "react";
import Logo from "../img/linx_logo.png";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    return(
        <div className="root background">
            <div className="flex position-center p-right-10 p-bottom-10">
                <div onClick={() => navigate('/contents')} className="container home_scale">
                    <h2>CONTENTS</h2>
                </div>
                <div className="space_module_midium-w" />
                <div onClick={() => navigate('/mypage')} className="container home_scale">
                    <h2>MY PAGE</h2>
                </div>
            </div>
            <div className="bottom_logo">
                <img src={Logo} />
                <p>PROMOTION EDITION</p>
            </div>
        </div>
    )
}

export default Home;